var render = function render(){var _vm=this,_c=_vm._self._c;return _c('grid-view',{attrs:{"title":"Критерии оценивания","create":"","create-title":"Создать элемент","url-create":"/scoring-criteria/create","url-update":"/scoring-criteria/update/:id","store-module":"hint","filter":"","headers":[
        { text: 'ID', value: 'id', width: 1 },
        { text: 'Название', value: 'name', width: 1, sortable: true },
        { text: 'Обновлен', value: 'updated_at', width: 1, sortable: true },
        { text: '', value: 'edit', width: 1, sortable: false },
        { text: '', value: 'create_similar', width: 1, sortable: false }
        // { text: '', value: 'remove', width: 1, sortable: false }
    ],"format":_vm.formatItems},scopedSlots:_vm._u([{key:"filter",fn:function({ filter }){return [_c('v-row',[_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Название","placeholder":"1","color":_vm.$const.color.primary},model:{value:(filter.name),callback:function ($$v) {_vm.$set(filter, "name", $$v)},expression:"filter.name"}})],1)],1)]}},{key:`title.right`,fn:function(){return [(_vm.isUserCan)?_c('v-btn',{staticClass:"ml-auto mr-2 white--text",attrs:{"to":"/scoring-criteria/task-relations","color":_vm.$const.color.primary}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-link ")]),_c('span',[_vm._v("Связи с заданиями")])],1):_vm._e()]},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }